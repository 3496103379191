import React from 'react';
import './ProjectsPage.css';
import Navbar from "../../components/Navbar/Navbar";
import Projects from "../../components/Projects/Projects";
import Footer from "../../components/Footer/Footer";

const ProjectsPage = () => (
    <div className="ProjectsPage">
        <div className={"inner-div"}>
            <Navbar/>
            <Projects/>
            <Footer />
        </div>
    </div>
);

ProjectsPage.propTypes = {};

ProjectsPage.defaultProps = {};

export default ProjectsPage;
