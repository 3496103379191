import React from 'react';
import styles from './Footer.module.css';

function Footer() {
    return (
        <div className={styles.Footer}>
        </div>
    );
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
