import React, {useEffect, useState} from 'react';
import styles from './Navbar.module.css';
import {useNavigate} from "react-router-dom";



function Navbar() {
	const navigate = useNavigate();
	const [sizeLayout, setSizeLayout] = useState(`${styles.innerNavDivBrowser}`);
	const [titleLayout, setTitleLayout] = useState(`${styles.titlesBrowser}`);
	const [linkLayout, setlinkLayout] = useState(`${styles.navlinksBrowser}`);
	const [linkSize, setLinkSize] = useState(0);
	useEffect(() => {
		function handleResize() {
			if(window.innerWidth >= 800) {
				setSizeLayout(`${styles.innerNavDivBrowser}`);
				setTitleLayout(`${styles.titlesBrowser}`);
				setlinkLayout(`${styles.navlinksBrowser}`);
			}
			else {
				setSizeLayout(`${styles.innerNavDivMobile}`);
				setTitleLayout(`${styles.titlesMobile}`);
				setlinkLayout(`${styles.navlinksMobile}`);
				if(window.innerWidth < 230) {
					setLinkSize(2);
				}
				else if (window.innerWidth < 450) {
					setLinkSize(1);
				}
				else {
					setLinkSize(0);
				}
			}
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, [sizeLayout, titleLayout, linkLayout]);
	return (
		<div className={styles.banner}>
			<div className={sizeLayout }>
				<div className={titleLayout}>
					<div className={styles.maintitle}>Will Dolan</div>
					<div className={styles.subtitle}>USC Computer Science and Philosophy Student</div>
				</div>
				<div className={linkLayout}>
					<a onClick={() => {
						navigate('/')
					}} href="/" className={styles.navlink}>About</a>
					{['|', '|', '\n'][linkSize]}
					<a onClick={() => {
						navigate('/involvement')
					}} href="/involvement" className={styles.navlink}>Involvement</a>
					{['|', '\n', '\n'][linkSize]}
					<a onClick={() => {
						navigate('/research')
					}} href="/research" className={styles.navlink}>Research/Projects</a>
				</div>

			</div>
		</div>
	);
}

Navbar.propTypes = {};

Navbar.defaultProps = {};

export default Navbar;
