
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import ProjectsPage from "./pages/ProjectsPage/ProjectsPage";
import InvolvementPage from "./pages/InvolvementPage/InvolvementPage";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={"/research"} element={<ProjectsPage />}/>
				<Route path={"/involvement"} element={<InvolvementPage />}/>
				<Route path="/" element={<HomePage />}/>
				<Route path="*" element={<Navigate to="/" replace/>}/>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
