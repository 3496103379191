import React from 'react';
import styles from './Projects.module.css';

const Projects = () => (
  <div className={styles.Projects}>
      <div className={styles.inner70Div}>
          <div className={styles.title}>
              Research & Projects
          </div>
          <div className={styles.paragraph}>
              <strong>Applegate Labs Research</strong> <br/>
              Researching novel uses of Optical Coherence Tomography scans of the inner ear for ML-enabled
              classification of pathologies.
          </div>

          <div className={styles.paragraph}>
              <strong><a href={"https://github.com/Will-Dolan/SnipeBot"}>SpotBot</a></strong><br/>
              Gamifying seeing your friends on campus. Spot them, send it to Slack, and bot will update your stats, leaderboard, etc.
              Hosted on AWS w/ Lambda, RDS
          </div>
          <div className={styles.paragraph}>
              <strong><a href={"https://devpost.com/software/language-buddy-4v32ka"}>Language Buddy</a></strong><br/>
              In less than 24 hours developed a LLM language exchange application able to understand niche slang
              in many different languages
              and dialects, showing an understanding of how language is truly spoken, rather than formally spoken. We
              placed
              second at USC's AI4Good Hackathon.
          </div>
          <div className={styles.paragraph}>
              <strong>Let's Go Camping</strong><br/>
              Webapp to connect you, your friends, and your favorite national parks based on shared preferences
              through National Parks' API.
          </div>
          <div className={styles.paragraph}>
              <strong><a href={"https://github.com/Will-Dolan/asl-alphabet"}>ASL Interpreter</a> </strong><br/>
              Created ASL interpreter for simple characters with pytorch.
          </div>
          <div className={styles.paragraph}>
              <strong><a href={"https://github.com/Will-Dolan/ITP216-FinalProject"}>Predicting Insurace Rates</a>
              </strong><br/>
              Simple flask/sklearn/pandas/numpy project to predict insurance rates based on health factors.
          </div>
          <div className={styles.paragraph}>
              <strong><a href={"https://github.com/Will-Dolan/COVID-CT"}>COVID CV</a> </strong><br/>
              Computer vision model to detect COVID against healthy/influenza lung images. Developed during open
              sourcing of COVID data during the summer of 2020.
          </div>
          <div className={styles.paragraph}>
              <strong><a href={"https://github.com/Will-Dolan/DiabetesHealthIndicators"}>Diabetes Health Indicators</a>
              </strong><br/>
              Study into prediction of future diabetes/prediabetes from current health data.
              Used numerous methods, including regression, boosted trees, and neural networks.
          </div>
          <div className={styles.paragraph}>
              <strong>Built a PC</strong><br/>
              Just built a PC during summer of 2023.
          </div>

          <div className={styles.paragraph}>
              Here's a list of some of the skills/tools I used for these and have experience in:
              <li>Languages: C, C++, Python, C#, Java, SQL, JavaScript, Assembly, TypeScript, HTML/CSS, bash scripting, opengl</li>
              <li>Frameworks: .NET, AngularJS, Django, Flask, React</li>
              <li>Developer Tools: Git, Visual Studio, Docker, AWS (Lambda, RDS, Amplify), Jupyter Notebooks, IntelliJ, Postman, Jira, Azure
                  DevOps, SSMS, Unity
              </li>
              <li>Libraries: PyTorch, pandas, NumPy, Selenium, SQLite, OpenCV, scikit-learn, Google Protocol Buffers,
                  OpenGL, CUDA
              </li>
          </div>
          <br/>
          <div>
              <div className={styles.title}>
                  Philosophy
              </div>
              <div className={styles.paragraph}>
                  In the philosophy classes I choose to take, I tend to focus on philosophy of mind,
                  perception, and language. I am interested in how the information we process
                  (and don't process) informs us of the world around us. I find these fields exceptionally
                  applicable to AI/ML and extended reality applications. As well as all of these, ethics
                  helps apply these to real life problems (shoutout <a href={"https://shiftsc.org"}>Shift SC</a>!).
              </div>
              <div className={styles.paragraph}>
                  A few of the topics I have written on:
                  <li>the applicability and consequences of the word 'trust' in LLMs</li>
                  <li>transhumanism and human enhancement</li>
                  <li>consequences and assigning blame from machine decision making</li>
              </div>
          </div>
      </div>
  </div>
);

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
