import React from 'react';
import './HomePage.css';
import Home from "../../components/Home/Home";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const HomePage = () => (
  <div className="HomePage">
      <div className={"inner-div"}>
          <Navbar />
          <Home />
          <Footer />
      </div>
  </div>
);

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
