import React, {useEffect, useState} from 'react';
import styles from './Home.module.css';
import shifthike from "../../assets/shifthike.jpg";
import github_asset from "../../assets/github-w-asset.png";
import linkedin_asset from "../../assets/linkedin-w-asset.png";

function Home() {


  const [photo_position, setPhotoPosition] = useState("selfinfo-browser");
  useEffect(() => {
    function handleResize() {
      if(window.innerWidth < 800) {
        setPhotoPosition(styles.selfinfoMobile);
      }
      else {
        setPhotoPosition(styles.selfinfoBrowser);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (

  <div className={styles.Home}>
    <div className={`${styles.inner70Div} `}>
      <div className={photo_position}>
        <img alt="Headshot of Will Dolan" width={250} className={styles.selfpicture} src={shifthike}></img>
        <div className={styles.contactinfoheader}>
          Contact Information
        </div>
        <div className={styles.contactinfo}>
          <a href={"https://github.com/Will-Dolan"}><img alt={"link to my github"} className={styles.socialLink} src={github_asset} /></a>
          <a href={"https://www.linkedin.com/in/will-dolan/"} ><img alt={"link to my linkedin"} className={styles.socialLink} src={linkedin_asset} /></a>
          <div>
            Emails:<br/>
            wrdolan@usc.edu<br/>
            willrdolan@gmail.com
          </div>
        </div>
      </div>

      <div className={styles.title}>
        About Me
      </div>
      <div className={styles.aboutme}>
        <div>
          I am a senior at the University of Southern California's
          Viterbi School of Engineering studying computer science with a minor
          in philosophy. My involvement and work reflect what I am interested
          in: <a className={styles.aboutMeLink} href={"https://caisplusplus.usc.edu/"}>machine learning</a>
          , <a className={styles.aboutMeLink} href={"https://sentiar.com/"}>extended reality</a>,
          and working towards impactful products and projects
          in <a className={styles.aboutMeLink} href={"https://shiftsc.org"}>socially responsible technology</a>.
        </div>

        <div className={styles.workExperience}>
          Previously, I have worked at:
        </div>
        <div className={styles.workExperience}>
          <strong>SentiAR</strong>: developing hands-free UI for Augmented Reality used in
          minimally-invasive cardiac ablation procedures.
          Gained deep knowledge and experience of AR UI/UX design, as well as design ideation
          and discovery through a months-long design prototyping study.<br/>
        </div>
        <div className={styles.workExperience}>
          <strong>Course Producer for Intro to AI (USC's CSCI 360)</strong>: teaching students in 200+
          person class the concepts and algorithms underlying intelligent systems. Topics
          include agents, problem solving, search, representation, reasoning, planning,
          and machine learning. Teaching python, numpy, pandas, scikit-learn, pytorch,
          and matplotlib to students during bi-weekly office hours.<br/>
        </div>
        <div className={styles.workExperience}>
          <strong>Stifel Financial Corp</strong>: advancing Anti-Money Laundering compliance efforts through
          improvements to internal client record tools and security fixes to prevent scraping of
          in-house research through insecure APIs. <br />
        </div>
        <div className={styles.workExperience}>
          <strong>Kitware</strong>: researched as part of medical team a way to reduce the imaging time and quantity of harmful
          contrast material for stroke victims needing CT scans. Achieved result of needing
          one less scan by developing an algorithm for manipulating CTP brain scans to extract
          important information normally found in CTA brain scans.
        </div>
      </div>
    </div>
  </div>
)}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
