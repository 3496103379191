import React from 'react';
import styles from './Involvement.module.css';

const Involvement = () => (
  <div className={styles.Involvement}>
      <div className={styles.inner70Div}>
          <div className={styles.title}>
              Involvement
          </div>
          <div id={'shift'} className={styles.paragraph}>
              <strong><a href={"https://shiftsc.org"}>Shift SC</a></strong> <br/>
              This past year, I was one of the co-presidents of Shift, USC's student-run organization focusing on
              ethical technology. Shift promotes ethical and socially responsible tech in and around USC through a
              number of initiatives focusing on function, like our speaker series team, career fair team, etc. or focusing
              on topic, like our AI ethics team, VR team, etc.
              <br/>
              <br/>
              Shift is constantly growing and we have an amazing team! Here's a list of a few of the things we did
              during my tenure:
              <li>Hosted a symposium bringing AI experts from Google, Juniper Networks, and more to USC</li>
              <li>Promoted careers in human-centered tech to USC for our inaugural career fair in the Fall</li>
              <li>Opened a job board with these tech for good companies on our website (find it <a
                  href={"https://www.shiftsc.org/tech4good-job-board"}>here</a>!)
              </li>
              <li>Advised Minnesota lawmakers on policy related to the digital wellbeing and social media use of
                  adolescents
              </li>
              <li>Presented to hundreds of students about safe practices online and mindful tech use with Delete the
                  Divide’s Tech Empowerment Day
              </li>
              <li>Expanding our AI ethics discussions throughout classes at USC and to a number of other clubs</li>
              <li>Held an XR pitch competition to promote ethical problem-solving with XR</li>
              <li>Hosted public events and discussions highlighting ethical issues in health tech and XR</li>
          </div>
          <div id={'cais'} className={styles.paragraph}>
              <strong><a href={"https://caisplusplus.usc.edu/"}>Center for AI in Society's Student Branch
                  (CAIS++)</a></strong> <br/>
              CAIS++ is a student organization that promotes the development of AI applications
              for social good. For CAIS++, I have worked as a curriculum lead, teaching incoming members
              machine learning theory and coding practice. This culminates with guiding the new members
              on their own projects for social good. I also do research through CAIS++, read more about it
              on <a href={"/research"}>the research page</a>.
          </div>
          <div id={'lme'} className={styles.paragraph}>
              <strong>Lockheed Martin Ethics in Engineering Case Competition</strong> <br/>
              I was one of two students selected to represent USC in our first year at a national case competition
              regarding ethics, engineering, and business issues in Bethesda, MD. Debated best solutions to
              remedying
              problem of insecure mission-critical software, placing in the top 20% of teams. Now, I enjoy helping
              coach the new teams each year.
          </div>
          <div id={'asee'} className={styles.paragraph}>
              <strong><a
                  href={"https://sites.usc.edu/asee-psw-2023/program/#:~:text=Searching%20for%20Good%3A%20Undergraduate%20Perspectives%20on%20Ethics%20in%20Engineering%20Education"}>
                  American Society for Engineering Education
              </a></strong> <br/>
              Spoke to ASEE educators and administrators about the value of student-led
              engineering ethics in undergraduate experiences.
          </div>
          <div id={'chess'} className={styles.paragraph}>
              <strong>Trojan Chess Club</strong> <br/>
              Weekly chess club and tournaments.
          </div>
          <div id={'ctf'} className={styles.paragraph}>
              <strong>USC CTF</strong> <br/>
              Capture the Flag Cybersecurity games.
          </div>
      </div>
  </div>
);

Involvement.propTypes = {};

Involvement.defaultProps = {};

export default Involvement;
