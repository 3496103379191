import React from 'react';
import './InvolvementPage.css';
import Navbar from "../../components/Navbar/Navbar";
import Involvement from "../../components/Involvement/Involvement";
import Footer from "../../components/Footer/Footer";

const InvolvementPage = () => (
    <div className="InvolvementPage">
        <div className={"inner-div"}>
            <Navbar/>
            <Involvement/>
            <Footer />
        </div>
    </div>
);

InvolvementPage.propTypes = {};

InvolvementPage.defaultProps = {};

export default InvolvementPage;
